import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import CallButton from "../components/callButton"
import { slugify } from "../helpers"
import ShopButton from "./shopButton";

const LocationPage = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor {
        edges {
          node {
            id
            name
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      associations: file(relativePath: { eq: "associations.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeChart: file(relativePath: { eq: "optic-letters.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kid: file(relativePath: { eq: "kid.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const location = props.pageContext.location
  location['type'] = 'location'
  const doctors = data.allContentfulDoctor.edges
  return (
    <Layout umLocation={location}>
      <SEO schemaData={location} title={location.name} />
      <div className="mx-auto flex font-sans flex-col lg:py-8">
        <div className="container mx-auto flex flex-col lg:flex-row lg:mb-6 ">
          <div className="flex w-full bg-gray-300 items-center mb-4">
            <Img
              imgStyle={{
                objectFit: "none",
                objectPosition: "50% 0%",
              }}
              className="h-40 sm:h-full w-full" fluid={data.kid.childImageSharp.fluid} alt="associations" />
          </div>
          <div className="w-full px-4 md:px-0">
            <h1 className="text-center  font-bold leading-tight text-navy-500 tracking-wider text-2xl lg:text-4xl mb-4">Looking for the best eye care provider in {location.name}?</h1>
            <div className="flex justify-center mb-4"><CallButton phone={location.phone} /></div>
            {location.name === 'Suwanee/Lawrenceville' && <div className=" flex justify-center mb-4 lg:mb-6"><ShopButton /></div>}
            <div className="hidden lg:flex text-navy-500 lg:flex-row">
              {console.log(location)}
              {location.reviews.map((review, index) => {
                if (index < 2) {
                  return <div key={review.id} className="flex flex-col text-left mb-4 items-center text-center lg:w-1/2 px-6 lg:p-6">
                    <svg className="mb-2 -ml-1" width="112" height="22" viewBox="0 0 112 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9997 15.8309L16.6647 19.25L15.1613 12.8059L20.1663 8.47004L13.5755 7.90171L10.9997 1.83337L8.42384 7.90171L1.83301 8.47004L6.82884 12.8059L5.33467 19.25L10.9997 15.8309Z" fill="#FCCF5C" />
                      <path d="M33.5 15.8309L38.9075 19.25L37.4725 12.8059L42.25 8.47004L35.9588 7.90171L33.5 1.83337L31.0412 7.90171L24.75 8.47004L29.5187 12.8059L28.0925 19.25L33.5 15.8309Z" fill="#FCCF5C" />
                      <path d="M55.9997 15.8309L61.6647 19.25L60.1613 12.8059L65.1663 8.47004L58.5755 7.90171L55.9997 1.83337L53.4238 7.90171L46.833 8.47004L51.8288 12.8059L50.3347 19.25L55.9997 15.8309Z" fill="#FCCF5C" />
                      <path d="M78.5 15.8309L83.9075 19.25L82.4725 12.8059L87.25 8.47004L80.9588 7.90171L78.5 1.83337L76.0412 7.90171L69.75 8.47004L74.5187 12.8059L73.0925 19.25L78.5 15.8309Z" fill="#FCCF5C" />
                      <path d="M101 15.8309L106.665 19.25L105.161 12.8059L110.166 8.47004L103.576 7.90171L101 1.83337L98.4238 7.90171L91.833 8.47004L96.8288 12.8059L95.3347 19.25L101 15.8309Z" fill="#FCCF5C" />
                    </svg>
                    <p className=" italic">{review.description.description + ' - ' + review.name}</p>
                  </div>
                } else { return null }
              })}
            </div>
            <div className="flex text-navy-500 flex-row lg:hidden">
              {location.reviews.map((review, index) => {
                if (index < 1) {
                  return <div key={review.id} className="flex flex-col text-left mb-4 items-center text-center lg:w-1/2 px-6 lg:p-6">
                    <svg className="mb-2 -ml-1" width="112" height="22" viewBox="0 0 112 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9997 15.8309L16.6647 19.25L15.1613 12.8059L20.1663 8.47004L13.5755 7.90171L10.9997 1.83337L8.42384 7.90171L1.83301 8.47004L6.82884 12.8059L5.33467 19.25L10.9997 15.8309Z" fill="#FCCF5C" />
                      <path d="M33.5 15.8309L38.9075 19.25L37.4725 12.8059L42.25 8.47004L35.9588 7.90171L33.5 1.83337L31.0412 7.90171L24.75 8.47004L29.5187 12.8059L28.0925 19.25L33.5 15.8309Z" fill="#FCCF5C" />
                      <path d="M55.9997 15.8309L61.6647 19.25L60.1613 12.8059L65.1663 8.47004L58.5755 7.90171L55.9997 1.83337L53.4238 7.90171L46.833 8.47004L51.8288 12.8059L50.3347 19.25L55.9997 15.8309Z" fill="#FCCF5C" />
                      <path d="M78.5 15.8309L83.9075 19.25L82.4725 12.8059L87.25 8.47004L80.9588 7.90171L78.5 1.83337L76.0412 7.90171L69.75 8.47004L74.5187 12.8059L73.0925 19.25L78.5 15.8309Z" fill="#FCCF5C" />
                      <path d="M101 15.8309L106.665 19.25L105.161 12.8059L110.166 8.47004L103.576 7.90171L101 1.83337L98.4238 7.90171L91.833 8.47004L96.8288 12.8059L95.3347 19.25L101 15.8309Z" fill="#FCCF5C" />
                    </svg>
                    <p className=" italic">{review.description.description + ' - ' + review.name}</p>
                  </div>
                } else { return null }
              })}
            </div>
          </div>
        </div>
        <div className="px-4 mb-6 hidden lg:block">
          <Img fluid={data.associations.childImageSharp.fluid} alt="associations" className="max-w-4xl mx-auto" />
        </div>

        <h2 className="text-center uppercase text-lg mb-4 lg:mt-8 text-navy-500 font-thin">your eye care doctors in {location.name}</h2>
        {/* <Link to="services" className="text-center mb-4 font-bold rounded text-navy-600 underline block lg:hidden">View eye care services in {location.name}</Link> */}
        <div className="flex mx-auto container flex-col items-center justify-center  px-2 mb-6 lg:flex-row lg:flex-wrap">
          {doctors.map(({ node: doctor }) => {
            return <Link to={'/optometrist-doctors/' + slugify(doctor.name)}
              key={doctor.id} aria-label={doctor.name + ' U & M Family Eye Care'}
              className=" text-center text-navy-600 mb-6 flex flex-col items-center">
              <Img className="w-48 rounded-full border-2 border-white lg:w-56 mb-2 lg:m-8" fluid={doctor.picture.localFile.childImageSharp.fluid} />
              <p className="font-bold">{doctor.name}</p>
            </Link>
          })}
        </div>

        <div className="px-4 mb-6 lg:hidden block">
          <Img fluid={data.associations.childImageSharp.fluid} alt="associations" className="max-w-4xl mx-auto" />
        </div>

        <div className="flex text-navy-500 flex-col lg:hidden">
          {location.reviews.map((review) => {
            return <div key={review.id} className="flex flex-col text-left mb-4 items-center text-center lg:w-1/2 px-6 lg:p-6">
              <svg className="mb-2 -ml-1" width="112" height="22" viewBox="0 0 112 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9997 15.8309L16.6647 19.25L15.1613 12.8059L20.1663 8.47004L13.5755 7.90171L10.9997 1.83337L8.42384 7.90171L1.83301 8.47004L6.82884 12.8059L5.33467 19.25L10.9997 15.8309Z" fill="#FCCF5C" />
                <path d="M33.5 15.8309L38.9075 19.25L37.4725 12.8059L42.25 8.47004L35.9588 7.90171L33.5 1.83337L31.0412 7.90171L24.75 8.47004L29.5187 12.8059L28.0925 19.25L33.5 15.8309Z" fill="#FCCF5C" />
                <path d="M55.9997 15.8309L61.6647 19.25L60.1613 12.8059L65.1663 8.47004L58.5755 7.90171L55.9997 1.83337L53.4238 7.90171L46.833 8.47004L51.8288 12.8059L50.3347 19.25L55.9997 15.8309Z" fill="#FCCF5C" />
                <path d="M78.5 15.8309L83.9075 19.25L82.4725 12.8059L87.25 8.47004L80.9588 7.90171L78.5 1.83337L76.0412 7.90171L69.75 8.47004L74.5187 12.8059L73.0925 19.25L78.5 15.8309Z" fill="#FCCF5C" />
                <path d="M101 15.8309L106.665 19.25L105.161 12.8059L110.166 8.47004L103.576 7.90171L101 1.83337L98.4238 7.90171L91.833 8.47004L96.8288 12.8059L95.3347 19.25L101 15.8309Z" fill="#FCCF5C" />
              </svg>
              <p className=" italic">{review.description.description + ' - ' + review.name}</p>
            </div>
          })}
        </div>
      </div>
    </Layout>
  )
}

export default LocationPage